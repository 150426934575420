/* TODO: move to lib as standard css */
html {
  height: 100%;
  overflow-x: hidden;
}

body {
  font-family: 'Roboto', sans-serif !important;
  font-size: 13px !important;
  min-height: 100%;
}

strong,
b {
  font-weight: bolder;
}

.text-center {
  text-align: center;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul {
  margin: 0;
}

.d-none {
  display: none;
}

input::-webkit-search-decoration,
input::-webkit-search-cancel-button,
input::-webkit-search-results-button,
input::-webkit-search-results-decoration {
  display: none;
}

/** Material input */
.MuiInput-underline:hover:not(.Mui-disabled):before {
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
}

a {
  text-decoration: inherit; /* no underline */
}

#spinner,
#overlay,
#spinner[style],
#spinner[style],
body > #spinner,
body > #overlay {
  display: none !important;
  opacity: 0 !important;
  visibility: hidden !important;
  background: transparent !important;
  color: transparent !important;
}

/* Chrome, Safari, Edge, Opera */
.numInput input::-webkit-outer-spin-button,
.numInput input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
